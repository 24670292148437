<template>
  <div id="stickersheet">
    <div id="app" :class="{ 'editing': edit_mode }">
      <Moveable
          v-for="(element, index) in elements"
          :key="index"
          :style="'left:' + element.left + 'px; top:' + element.top + 'px; z-index:' + (index + 1) + ';'"
          :class="'moveable ' + element.className"
          v-bind="moveable"
          @drag="handleDrag"
          @resize="handleResize"
          @scale="handleScale"
          @rotate="handleRotate"
          @warp="handleWarp"
          @pinch="handlePinch"
          :ref="'moveable_' + element.className"
      >
        <img
            :ref="'image_' + element.className"
            :src="element.imgSource"
            :width="element.imgWidth"
            :height="element.imgHeight"
            @click.prevent="handleClick(element.className)"
        >
      </Moveable>

      <div class="safearea">SAFE PRINT AREA</div>

      <!-- Control Button -->
      <div class="edit-control no-print">
        <button @click="handleEditMode">
          {{ ! edit_mode ? 'EDIT' : 'STICK' }}
        </button>
        <button value="PRINT" onclick="window.print()">
          PRINT
        </button>
        <button value="RESET" onclick="window.location.reload(true)">
          RESET
        </button>
      </div>
      <!-- Happy New Year -->
      <div class="happy no-print">
        ※<br>\(^o^)/※<br>Happy<br>New Year<br>2021<br>※<br>
      </div>
    </div>
  </div>
</template>


<script>
import Moveable from 'vue-moveable';
import elements from "@/misc/elements";

export default {
  name: 'App',
  components: {
      Moveable
  },
  data() {
      return {
          edit_mode: false,
          elements: elements,
          active_element: null,
          moveable: {
            draggable: true,
            throttleDrag: 0,
            resizable: false,
            throttleResize: 1,
            keepRatio: true,
            scalable: false,
            throttleScale: 0,
            rotatable: true,
            throttleRotate: 0,
            pinchable: true, // ["draggable", "resizable", "scalable", "rotatable"]
            origin: false,
          }
      }
  },
  mounted() {
      const elements = document.querySelectorAll('.moveable-control, .moveable-line');
      elements.forEach((el) => el.style.display = 'none');
  },
  methods: {
    handleClick(className)
    {
        if (! this.edit_mode)
          return;

        const element = this.getRefElement(className);

        if(element.className.includes('active') && this.active_element === className)
          return;

        element.style.zIndex = this.getMaxIndex() + 1;
        element.className = element.className.concat(' active');

        if(this.active_element && this.active_element !== className) {
            const previousElement = this.getRefElement(this.active_element);
            previousElement.className = previousElement.className.replace('active', '');
        }

        this.active_element = className;
    },
    getRefElement(className) {
        return this.$refs['moveable_' + className][0].$el;
    },
    getMaxIndex() {
        const moveables = Object.keys(this.$refs)
            .filter((ref) => ref.includes('moveable'))
            .map((val) => {
              return {
                [val]: Number(this.$refs[val][0].$el.style.zIndex)
              }
            });

        return Math.max.apply(Math, moveables.map(object => object[Object.keys(object)[0]]));
      },
    handleEditMode() {
      let elements = document.querySelectorAll('.moveable-control, .moveable-line');

      if (this.edit_mode) {
        elements.forEach((el) => el.style.display = 'none');
      } else {
        elements.forEach((el) => el.style.display = 'inline-block');
      }

      this.edit_mode = ! this.edit_mode;
    },
    handleDrag({target, transform}) {
      if(! this.edit_mode)
        return;

      this.handleClick(target.className.split(' ')[1]);
      target.style.transform = transform;
    },
    handleResize({
                   target, width, height, delta,
                 }) {
      if(! this.edit_mode)
        return;

      this.handleClick(target.className.split(' ')[1]);

      delta[0] && (target.style.width = `${width}px`);
      delta[1] && (target.style.height = `${height}px`);
    },
    handleScale({target, transform, scale}) {
      if(! this.edit_mode)
        return;

      this.handleClick(target.className.split(' ')[1]);
      target.style.transform = transform;
    },
    handleRotate({target, dist, transform}) {
      if(! this.edit_mode)
        return;

      this.handleClick(target.className.split(' ')[1]);
      target.style.transform = transform;
    },
    handleWarp({target, transform}) {
      if(! this.edit_mode)
        return;

      this.handleClick(target.className.split(' ')[1]);
      target.style.transform = transform;
    },
    handlePinch({target}) {
      if(! this.edit_mode)
        return;

      this.handleClick(target.className.split(' ')[1]);
    },
  }
}
</script>


<style>

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

html {
  min-height: 100vh;
  cursor: default;
}
body, html {
  width: 100%;
  height: 100%;

  padding: 0;
  margin: 0;

  color: #F0F;
  background-color: #FFF;

  font-family: 'Inter', sans-serif;
  font-size: 30px;
  line-height: 30px;
}
#stickersheet {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  min-width: 720px;
  min-height: 720px;
}
#app {
  position: relative;

  width: 700px;
  height: 700px;
}
.editing#app {
  border-radius: 10px;
  border: 3px solid rgba(255, 0, 255, 0.2);
  background-color: rgba(255, 0, 255, 0.05);
}
.safearea {
  opacity: 0;
  font-size: 15px;
  line-height: 15px;
  position: absolute;
  bottom: 270px;
  left: 5px;
  color: rgba(255, 0, 255, 0.5);

  user-select: none;
  pointer-events: none;

  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-transform-origin: left top;
  transform-origin: left top;
}
.editing .safearea {
  opacity: 100;
}
.edit-control {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 99999999999;
  text-align: right;
}
.happy {
  user-select: none;
  pointer-events: none;

  position: fixed;
  left: 20px;
  bottom: 20px;
}
.moveable {
  position: absolute;
  user-select: none;
  pointer-events: none;
}
.editing > .moveable {
  user-select: initial;
  pointer-events: initial;
}
.editing > .moveable:hover {
  cursor: grab;
}
.editing > .moveable:active {
  cursor: grabbing;
}
.moveable img {
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.3));
}


@media print {
  #app {
    border: none;
    background-color: transparent;
  }
  .editing#app {
    border: none;
    background-color: transparent;
  }
  .safearea,
  .no-print {
    display:none;
  }
  .moveable-line {
    display:none !important;
  }
  .moveable-control {
    display:none !important;
  }
}
button {
  font: inherit;

  border-radius: 5px;
  background-clip: padding-box;
  overflow: visible;

  border: 0;

  text-align: center;
  white-space: nowrap;
  width: auto;
  max-width: inherit;

  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  box-sizing: border-box;

  color: #FFF;
  background-color: #F0F;

  font-family: 'Inter', sans-serif;

  font-size: 20px;
  line-height: 20px;
  height: 40px;
  margin: 5px;
  padding: 10px;
  vertical-align: middle;

  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.3));
}
button:hover{
  color: #F0F;
  background-color: #FFF;
  cursor: pointer;
}
button:focus {
  outline: none;
}
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring {
  outline: 1px solid ButtonText;
}

</style>

